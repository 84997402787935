// Компонент формы первичной регистрации

import React, { Component } from "react";
import { connect } from "react-redux";
import Media from "react-media";

import apiService from "../../../services/apiService";
import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import { checkPhoneNumber, setLoadStatus, addMessage, windowOpen, route_from_stage_res } from '../../../scripts/functions';
import MyInput from '../../../utils/input'
import SidePanel from './side-panel'
import history from "../../../scripts/history";
import MyOtp from "../../../utils/my-otp";
import { AGREE_TEXT } from "../../../scripts/indexphp"

import InputMask from 'react-input-mask';

class SignUp extends Component {

  state = {
    regState: 0,
    otpValue: '',
    canGet: false,
    time: null,
    agree: true
  };

  _mounted = false;
  phoneNumber = null;
  n = true;
  errorMessage = '';
  apiService = new apiService();

  componentDidMount() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('companyId')
    localStorage.removeItem('phoneNumber')
    sessionStorage.removeItem('regTicketId')

    this.nameInput.focus();
  }

  componentDidUpdate() {
    this._mounted = true;
    this.otpInputsRequired();
    if (!this.state.regState) {
      this.setState((state) => {
        state.otpValue = '';
      });
      clearTimeout(this.timerId)
    }
  };

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this.timerId)
  };

  // получение кода по смс и тикета в ответе сервера
  getSms = async () => {
    return await this.apiService.getResource('api/registration/sendSmsToVerifyCode', 'POST', { 'phoneNumber': this.phoneNumber }, true)
      .then((res) => {
        if (!res.success) {
          return false
        }
        this.phoneNumber = res.phoneNumber
        sessionStorage.setItem('regTicketId', res.ticketId)
        return res
      })
  };

  checkUsageNumber = async () => {
    if (this.props.state.load) {
      return false
    }
    await setLoadStatus(this.props.load, false);
    await setLoadStatus(this.props.load, true);

    await this.getSms()
      .then(async (res) => {
        // console.log(res)
        if (!res.success) {
          addMessage('#phone', res.message, true);
        } else {
          sessionStorage.setItem('regTicketId', res.ticketId);
          await this.regStateChanger(1);
          this._mounted && this.setState({ time: res.timeout });
          this.timer(this);
        }
      })

    await setLoadStatus(this.props.load, false);
  }

  regStateChanger = (newState) => this._mounted && this.setState({ regState: newState })

  otpInputsRequired = () => {
    const inputs = document.querySelectorAll('.otp-input-style');
    inputs.forEach((el) => {
      if (!el.hasAttribute('required')) {
        el.setAttribute('required', '')
      }
    })
  };

  onChangeOtpInput = async (otp) => {
    const otpText = String(otp)[String(otp).length - 1];
    if (/[0-9]/.test(otpText) || otpText === undefined) {
      this._mounted && this.setState({ otpValue: otp });
    }
    return true
  };

  checkValidityOtp = async () => {
    this.n = true;

    document.querySelectorAll('.otp-input-style')
      .forEach((el) => {
        if (!el.checkValidity()) return this.n = false
      });

    if (!this.n) addMessage('.otp-style', "Необходимо заполнить поля");
    if (!this.n || this.props.state.load) return false;

    setLoadStatus(this.props.load, true);

    const domain=this.apiService.emaildomain();

    await this.apiService
      .getResource('api/registration/verifySmsCode', 'POST', { 'verifyCode': this.state.otpValue, 'ticket': sessionStorage.getItem('regTicketId'), domain })
      .then(async (res) => {
        // console.log(res)
        const phone = res.phoneNumber;

        if (!res.token) {
          addMessage('.otp-label', res.errorMessage);
        } else {
          localStorage.setItem('phoneNumber', phone);
          localStorage.setItem('access_token', res.token);

          if (!res.companyId) {
            this.props.curStepChanger(1);
            history.push(`/registration/step-1`)
            localStorage.removeItem('companyId')
            return false
          }

          localStorage.setItem('companyId', res.companyId)
          return await this.apiService.getResource('api/registration/checkRegistrationStage', 'POST', { companyId: res.companyId })
            .then((result) => {
              const { stage } = result
              const novroute = route_from_stage_res ( result )
              this.props.curStepChanger(stage);
              history.push(novroute)
            })
        }
        return true
      })
    setLoadStatus(this.props.load, false);
  };

  timer = (arg) => {
    this._mounted && arg.setState((state) => {
      state.canGet = false;
    });
    const span1 = document.getElementById('timer-span-1');
    const span2 = document.getElementById('timer-span-2');
    let seconds = this.state.time;
    span1.innerHTML = 'Отправить код повторно можно через ';
    span2.innerHTML = seconds + ' сек.';

    const timerFunc = () => {
      seconds--;
      span2.innerHTML = seconds + ' сек.';
      if (seconds > 0) {
        // console.log(seconds)
        return this.timerId = setTimeout(timerFunc, 1000);
      } else {
        this._mounted && arg.setState((state) => {
          state.canGet = true;
        });
        span1.innerHTML = '';
        span2.innerHTML = 'Получить новый код';
      }
    };

    this.timerId = setTimeout(timerFunc, 1000)
  };

  onSubmit = async (e, showInvalid) => {
    e.preventDefault();

    this.phoneNumber = checkPhoneNumber(document.getElementById('phone').value);
    this.n = false;
    document.getElementById('sign-up-form')
      .querySelectorAll('input:not([type=submit])')
      .forEach((el) => {
        if (!el.checkValidity()) {
          showInvalid(el);
          return (
            e.stopPropagation(),
            this.n = true
          );
        }
      });
    if (!this.n) {
      this.checkUsageNumber();
    }
  };

  openPdf = (e, file) => {
    e.preventDefault();
    windowOpen(file)
  }

  render() {
    const isFirstStep = !this.state.regState;
    const { agree } = this.state;
    return (
      <>
        <Media query="(min-width: 1025px)" render={() => (
          <SidePanel />
        )} />
        <ValidFuncConsumer>
          {({ showInvalid }) => {
            return (
              <div className="sign-in-and-sign-up-container">
                <form noValidate id={isFirstStep ? "sign-up-form" : 'sign-up-form-second-part'} className="sign-up-forms" onSubmit={(e) => isFirstStep ? this.onSubmit(e, showInvalid) : e.preventDefault()}>

                  {isFirstStep ? <>
                    <h1>Регистрация</h1>
                    <InputMask mask="+7 999 999 99 99" maskChar="" noSpaceBetweenChars={true}>
                      {() => <MyInput type="tel" title="Ваш мобильный телефон" autoComplete="tel" id="phone" name="phone" messagetext="Введите номер в формате +7 ХХХ ХХХ ХХ ХХ" pattern="^\+7\s{1}[0-9]{3}\s{1}[0-9]{3}\s{1}[0-9]{2}\s{1}[0-9]{2}$" placeholder="+7 ХХХ ХХХ ХХ ХХ" required ref={(input) => { this.nameInput = input; }} />}
                    </InputMask>

                    <div className="additional-notice marb-24">Введите реальный номер сотового телефона руководителя. Этот номер будет использоваться для подтверждения через СМС значимых операций (авторизация, изменение данных агентства, подписание электронных документов, выплата вознаграждения).»</div>
                    <div className="agree-with-container">
                      <div className={agree ? "app-checkbox active" : 'app-checkbox'} onClick={() => this.setState({ agree: !this.state.agree })}></div>
                      <div className="agree-with" dangerouslySetInnerHTML={{ __html: AGREE_TEXT }}></div>
                    </div>
                    <input className={!agree ? "primary-btn app-btn disabled" : "primary-btn app-btn"} disabled={!agree} type={'submit'} value="Продолжить" />
                    <div className="additional-notice">Отправим SMS-код для подтверждения номера</div>

                  </> : <>

                    <label className="otp-label">
                      <h1>Введите проверочный код</h1>
                      <div className="sended-sms additional-notice">
                        Код отправлен на номер <span className="reg-tel">{this.phoneNumber}</span>
                      </div>
                      <MyOtp
                        onChange={otp => { this.onChangeOtpInput(otp) }}
                        onFinish={this.checkValidityOtp}
                        numInputs={4}
                        value={this.state.otpValue}
                        containerStyle="otp-style"
                        inputStyle="otp-input-style"
                        focusStyle={{ "borderBottom": "solid #828282 2px" }}
                        doneStyle={{ "borderBottom": "solid #828282 2px" }}
                        shouldAutoFocus={true}
                      />
                    </label>

                    <button className={`primary-btn app-btn otp-btn`} onClick={() => this.checkValidityOtp()}>Продолжить</button>

                    <div className="additional-notice timer-span-wrap" onClick={async () => {
                      if (this.state.canGet) {
                        this._mounted && this.setState({ otpValue: '' });
                        document.querySelectorAll('.otp-input-style')
                          .forEach((el) => {
                            el.classList.remove("otp-input-style-past");
                          });
                        const firstOtpInput = document.querySelector('.otp-input-style:first-of-type');
                        firstOtpInput.focus();
                        await this.getSms();
                        this.timer(this);
                      }
                    }}>
                      <span id="timer-span-1">Отправить код повторно можно через </span><span id="timer-span-2"></span>
                    </div>

                    <button type="button" className="reg-link fs-14" onClick={() => {
                      this.regStateChanger(0);
                      this._mounted && this.setState({ otpValue: '' });
                    }}>Изменить номер телефона</button>
                  </>}

                </form>
              </div>
            )
          }}
        </ValidFuncConsumer>
      </>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    },
    curStepChanger: (number) => {
      return dispatch({ type: 'STEP_CHANGE', payload: number })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

// Класс для проверки валидности инпутов
import {scrollPageTo} from '../scripts/functions'

export default class ValidFuncService {

  n = true;

  checkValidity = (id) => {
    this.n = false;
    let arr = [];

    if(document.getElementById(id)) {
      document.getElementById(id)
        .querySelectorAll('input:not([type=submit])')
        .forEach((el) => {
          if(el.name !== 'disabled' && !el.checkValidity()) {
            if(arr.length === 0) {
              scrollPageTo(el);
            }
            arr.push(el)
            this.showInvalid(el);
            return (
              this.n = true
            );
          }
        });
    }

    return this.n
  };
  
  showInvalid = (t) => {
    const input = t;
    
    if(!input.checkValidity()) {
      // const parent = input.parentElement;
      const parent = input.closest('label');
      parent.classList.add('has-error');

      parent.setAttribute('messagetext', !input.value ? 'Необходимо заполнить поле' : input.getAttribute('messagetext'))
    }
  };

  showValid = (t) => {
    const input = t;
    // const parent = input.parentElement;
    const parent = input.closest('label');
    if(parent.classList.contains('has-error')) {
      parent.classList.add('has-error');
      parent.removeAttribute('messagetext');
      
      parent.classList.remove('has-error');
    }
  };

  bindClickSubmit = (event) => {
    if(event.keyCode === 13) {
      const btn = document.getElementById('app-btn');
      if(btn) btn.click();
    }
  };
}

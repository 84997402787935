// Универсальный класс для запросов к апи

import {WELCOMEAPI} from "../scripts/indexphp"

export default class ApiService {
  constructor() {
    this._apiBase = WELCOMEAPI
  }

  /* 
  этот домен будет в ссылке для проверки почты, при отладке с ip адресов и локальных доменов
  если указать домен неверно то почта никогда не отправится
  */
    
  emaildomain() {
    let d=window.location.host
    if (!d.match(/\.(sereniti\.ru|eto\.travel)$/)) {
      d='localhost';
    }
    return d
  }

  async getResource(url,method,data)  {
    let res;

    const headers =  {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }

    const collectedUrl = this._apiBase + url;

    if(method === 'GET' || method === 'get') {
      res = await fetch(`${collectedUrl}`, {
        method: method,
        headers: headers
      });
    } else {
      res = await fetch(`${collectedUrl}`, {
        method: method,
        body: JSON.stringify(data),
        headers: headers
      });
    }

    return res.json().then( 
      otvet => {
        let errNeedAuth = 401 === res.status ? true : false
        return { ...otvet, errNeedAuth }
      },
      err => {
        console.error('getResource', err)
        return {success:false, message: 'не json'}
      } 
    )
  }
}

// получаем свойства заданные на страничке в php файлике


export const AGREE_TEXT=get('AGREE_TEXT')
export const WELCOMEAPI=get('WELCOMEAPI','/welcomeapi')
export const DADATA=get('DADATA')

function get(n, def) {
    const d= def===undefined ? n : def
    let w=window.APP
    if (w) {
        w=w[n];
    }
    return w===undefined ? d : w
}
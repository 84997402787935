// Компонент входа/первичной регистрации

import React from "react";
import MainRegImg from "./main-reg-img.js";

const SidePanel = () => {
  return (    
    <div className="side-panel">
      <div className="side-panel-middle">
        <div className="side-panel-top-text">
          Единый кабинет турагента для оплаты всех туров, билетов и отелей
        </div>

        <MainRegImg />
        
        <div className="side-panel-bottom-text">Более 10 000 турагентов выбрали «ЭТО трэвел» как самую удобную и технологичную платформу</div>
      </div>
    </div>
  )
};

export default SidePanel;
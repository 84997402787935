import { Component } from "react"
import { connect } from "react-redux"
import FatalError from "./fatal"
import apiService from "../services/apiService"
import history from "../scripts/history"
import {route_from_stage_res} from "../scripts/functions"

class ByToken extends Component {

    state = {
        wait: true,
        ok: false, 
        err: null,
    }

    api = new apiService()


    componentDidMount() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('companyId')
        localStorage.removeItem('phoneNumber')
        sessionStorage.removeItem('regTicketId')
        
        this.processToken()
    }

    processToken = () => {
        const { token, curStepChanger, load } = this.props

        load(true)
        localStorage.setItem('access_token', token)
        this.api.getResource(
            'api/registration/getUserInfo', 'POST', { token }
        ).then( 
            res => {
                const { phoneNumber, companyId } = res
                if (!companyId || !phoneNumber) {
                    return Promise.reject( res )
                }
                localStorage.setItem('companyId', companyId)
                localStorage.setItem('phoneNumber', phoneNumber)
                return this.api.getResource(
                    'api/registration/checkRegistrationStage', 'POST', { companyId }
                )
            }
        ).then(
            res => {
                load(false)
                const { stage } = res
                if (!stage) {
                    return Promise.reject( res )
                }
                const novroute = route_from_stage_res ( res )
                curStepChanger( stage )

                const wait = false
                const ok = true
                this.setState( { wait , ok } )

                history.replace( novroute )

                return true
            }
        ).catch(
            err => {
                console.error( 'processToken', err )
                load(false)
                const wait = false
                const ok = false
                this.setState( { wait , ok,  err  } )
                return false
            }
        )
    }

    render() {
        let {wait, ok, err}  = this.state

        return (
            <div className="sign-in-and-sign-up-container">
                <h1>Вход</h1>

                {!wait && ok && (
                    <div className="message-text">
                        Успешно! Переходим к анкете
                    </div>
                )}
                {!wait && !ok && (
                    <FatalError err={err} />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        load: (bool) => {
          return dispatch({ type: 'LOAD_CHANGE', payload: bool })
        },
        curStepChanger: (number) => {
          return dispatch({ type: 'STEP_CHANGE', payload: number })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ByToken)


import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import cross from '../../../img/cross.svg'
import okSymbol from '../../../img/ok-symbol.svg'
import apiService from "../../../services/apiService"

class EmailConfirm extends Component {

  state = {
    confirmedEmail:  false,
    error: '',
    email: '',
  }

  api = new apiService()

  componentDidMount() {
    this.checkEmailConfirmed()
  }

  checkEmailConfirmed = () => {
    this.props.load(true)
    const params = new URLSearchParams(window.location.search)
    const email = params.get('email') || '';
    const token = params.get('token');
    const nov=new URLSearchParams();
    nov.set('email', email)
    nov.set('token', token)
    return this.api.getResource(`api/summarize/confirmEmail/?${nov.toString()}`, 'GET', null).then(
      responce => {
        this.props.load(false)
        if (responce.success) {
          this.setState({confirmedEmail: true, email})
        }else{
          let message=responce.message || 'что-то пошло не так (checkEmailConfirmed)'
          this.setState({
            confirmedEmail: false, 
            error: message,
            email,
          })
        }
        return
      }
    ).catch( err => {
      console.log('checkEmailConfirmed', err)
      this.props.load(false)
      return
    } )
  }

  Message = () => {
    const {confirmedEmail, error, email}=this.state
    return (
      <div className="message-text">
        <div className="data-item-inner">
          <div className={`image-div ${confirmedEmail ? 'done' : 'wrong'}`}><img alt="" src={confirmedEmail ? okSymbol : cross} /></div>
        </div>
        {confirmedEmail ? ( 
          <h1>{email} подтвержден</h1>
        ) : (
          <>
            <h1>{email}</h1>
            <div className="redgvn">{error}</div>
            <div className="text-div" style={{marginTop: '20px', textAlign: 'center'}}>
              Возможно, прошло больше суток с момента отправки письма со ссылкой.
              <br />Нажмите ВОЙТИ и попробуйте еще раз
              <br />
            </div>
          </>
        )}
        <Link to='/enter' className="primary-btn app-btn">
          Войти
        </Link>
      </div>
    )
  }


  render() {
    return (
      <div className="reg-steps-container">
        {this.props.state.load 
          ? (<h1>Подтверждаем емейл</h1> ) 
          : this.Message()
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: state
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    },
  }
}

export default connect( mapStateToProps , mapDispatchToProps )(EmailConfirm)

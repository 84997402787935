// Компонент для загрузки файлов

import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import Media from 'react-media';
import { removeMessage } from '../scripts/functions';
import pdfIcon from '../img/pdf-icon.png'
import loader from '../img/uploader.svg'

export default class MyDropzone extends Component  {
  constructor(props) {
    super(props)

    this.multiple = this.props.multiple
    this.addedNew = false;
  }

  state = {
    file: false,
  }

  componentDidMount() {
    if(this.getFile() && !this.state.file) {
      this.setState({file: this.getFile()})
    }
  };

  componentDidUpdate() {
    const {file} = this.state; 
    if(this.getFile() && (!file || (file !== this.getFile() && !this.addedNew))) {
      this.setState({file: this.getFile()})
    }

    this.clearFiles()
  };

  clearFiles = () => {
    if(this.props.clearFiles) {
      this.props.setClearStatus();
      this.setState({file: false})
    }
  };

  getFile = () => this.props.multiple ? this.props.photo[this.props.dropzoneInputId] : this.props.photo;

  filesStateChanger = file => this.props.filesStateChanger(file, this.props.dropzoneInputId);
  
  onDropAccepted = async (files) => {
    this.addedNew = true;
    const names = [
      'passportPhoto',
      'passportRegistrationPagePhoto',
      'passportEarlyPagePhoto'
    ]
    await this.setState({
      file: files.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        altName: names[this.props.dropzoneInputId]
      }))
    })
    this.filesStateChanger(this.state.file)
    
    removeMessage('.my-dropzone-row');
  }

  removeNewFiles = async (name, event) => {
    event.stopPropagation();
    let count;
    let {file} = this.state;
     file.forEach((el, index) => {
      if(el.altName === name) {
        count = index;
        return false
      }
    });
    const newFiles = file.slice(0, count).concat(file.slice(count + 1, file.length));
    await this.filesStateChanger(newFiles)
  };
    
  render() {
    const {file} = this.state;
    const {dropzoneInputId, content, altContent, showLoader} = this.props;
    
    const photo = file && file[0] ? file[0] : null;
    const maxSize = 1024 * 1024 * 10;

    const Files = ({tooLarge}) => {
      const isFileTooLarge = tooLarge === "file-too-large";
      return content ? content : showLoader ? (
        <div style={{width: '100%', display: 'flex',justifyContent: 'center'}}>
          <img alt="загрузка..." src={loader} style={{width: '160px', height: 'auto'}}/>
        </div>
      ) : isFileTooLarge ? (
        <Media queries={{
          small: `(max-width: 1024px)`,
          large: `(min-width: 1025px)`
        }}>
          {matches => (
            matches.large ?
              <div className="large-dz-message file-too-large">
                <div className="large-dz-message-image "></div>
                <div>Размер файла превышает допустимый</div>
              </div>
            : matches.small ? <div className="small-dz-message">Размер файла превышает допустимый</div> : null
          )}
        </Media>
      ) : photo ? (
        <div key={photo.name} className="photos-container-photo">
          <div className="new-loaded_file-image-container">
            <img src={photo.type === 'application/pdf' ? pdfIcon : photo.preview} alt={photo.name}/>
            <div className="new-loaded_file-close-btn" onClick={(e) => (this.removeNewFiles(photo.altName, e))}>
              <svg>
                <line x1="0%" y1="0%" x2="100%" y2="100%" stroke="#000000" strokeWidth="1"/>
                <line x1="100%" y1="0%" x2="0%" y2="100%" stroke="#000000" strokeWidth="1"/>
              </svg>    
            </div>
          </div>
          <div className="new-loaded_file-name">{photo.name.slice(0, photo.name.lastIndexOf("."))}</div>
          <div className="new-loaded_file-type">{photo.type.replace(/^image[/]/, '')}, {Math.round(+photo.size / 1024)} KB</div>
        </div>
      ) : (
        <Media queries={{
          small: `(max-width: 1024px)`,
          large: `(min-width: 1025px)`
        }}>
          {matches => (
            matches.large ?
              <div className="large-dz-message">
                <div className="large-dz-message-image"></div>
                <div>Перетащите скан сюда или <span>выберите файл</span></div>
              </div>
            : matches.small ? <div className="small-dz-message">Нажмите, чтобы выбрать фото в галерее</div> : null
          )}
        </Media>
      )
    };

    return (
      <Dropzone
        accept='image/*, application/pdf'
        multiple={this.multiple}
        onDropAccepted={this.onDropAccepted}
        minSize={0}
        maxSize={maxSize}
      >
        {({getRootProps, getInputProps, fileRejections}) => (
          <div className="dz-container">
            <input {...getInputProps({id: `dropzone-input-${dropzoneInputId}`})} />
            <div className="dz-message"{...getRootProps()}>
              <Files tooLarge={fileRejections[0] ? fileRejections[0].errors[0].code : false}/>
            </div> 
            {altContent ? (
              <div className="download-file-btn" {...getRootProps()}>Загрузить заново</div>
            ) : null}
          </div>
        )}
      </Dropzone>
    )
  }
}
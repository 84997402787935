import React, { Component } from "react"
import cross from '../img/cross.svg'
import { Link } from 'react-router-dom'

function isObj(x) {
    return x && 'object'==typeof(x)
}

class Fatal extends Component {

    render() {
        let err = this.props.err
        err = isObj(err) ? err : {}
        let {message, errNeedAuth}  = err
        if (!message) {
            message='Что-то пошло не так!'
        }

        return (
            <div className="message-text">
                <div className="data-item-inner">
                  <div className="image-div wrong"><img alt="" src={cross} /></div>
                </div>

                {errNeedAuth && (
                    <>
                    <div className="text-div">
                        Пожалуйста войдите заново
                    </div>
                    <Link to='/enter' className="primary-btn app-btn">
                        Войти
                    </Link>
                    </>
                )}

                {!errNeedAuth && (
                    <>
                    <div className="text-div">
                        {message}
                    </div>
                    <div className="text-div">
                        Проверьте сеть, попробуйте перегрузить страничку
                        <br />Если не поможет - войдите заново.
                    </div>
                    <Link to='/enter' className="primary-btn app-btn">
                        Войти заново
                    </Link>
                    </>
                )}
            </div>
        )
    }
}

export default Fatal

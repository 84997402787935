// компонент первого шага расширенной регистрации

import React, { Component } from "react";
import { connect } from "react-redux";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import ApiService from "../../../services/apiService";
import MyInput from "../../../utils/input";
import history from "../../../scripts/history";
import CheckboxList from "../../../utils/checkbox";
import { isEquivalent, phoneNumberCutter, setLoadStatus, } from "../../../scripts/functions";

class RegStepFirst extends Component {

  options = [
    'LegalEntity',
    'Individual',
    'Freelance',
  ]

  optionsCheckINN = {
    LEGAL: 'LegalEntity',
    INDIVIDUAL: 'Individual',
    FREELANCE: 'Freelance',
  }

  state = {
    changed: false,
    inn: '',
    innIsCorrect: false,
    prevType: null,
    institutionType: '',
    agree: true,
    legalAddress: {},
    companyPhone: '',
    companyName: '',
    ceo_name: '',
    ceo_post: '',
    zipCode: '',
    city: '',
    street: '',
    toCreate: true,
    innerr: '',
  };

  stateCopy = {};

  apiService = new ApiService();
  _mounted = false

  async componentDidMount() {
    this._mounted = true
    this.props.updateCurStep(0);
    const companyId = localStorage.getItem('companyId');
    if (companyId) {
      this.getData(companyId);
    }
  };

  componentWillUnmount() {
    this._mounted = false
  }

  checkInn = async (checkValidity) => {
    this.setState({innerr:''})
    if (checkValidity && checkValidity('reg-step-first-form')) return false
    await setLoadStatus(this.props.load, true);
    return await this.apiService.getResource('api/companyInfo/getCompanyInfo', 'POST', { inn: this.state.inn.replaceAll(' ', '') })
      .then(async (res) => {
        // console.log(res)
        await setLoadStatus(this.props.load, false);

        if (!this._mounted) return false


        if (res.success) {
          const { data } = res;
          if (data.isActive) {
            const type = this.optionsCheckINN[res.data.type]
            this.setState({
              institutionType: type || 'Freelance',
              innIsCorrect: data.inn,
              legalAddress: {
                city: data.city,
                zipCode: data.postal_code,
                street: data.street,
              },
              companyName: data.name,
              ceo_name: data.ceo_name,
              ceo_post: data.ceo_post,
            })
          } else {
            let innerr = `Организация ${data.name} ИНН ${data.inn} ликвидирована. Вы можете зарегистрироваться и работать с нами как фрилансер.`
            this.setState({innerr})
          }
        } else {
          let innerr=res.message || 'не удалось!'
          this.setState({innerr})
        }

        return true
      })
      .catch((error) => {
        console.error('checkInn', error)
      })
  };

  getData = (companyId) => {
    this.props.load(true);
    return this.apiService.getResource(`api/companyInfo?companyId=${companyId}`, "GET", {})
      .then((res) => {
        this.props.load(false);
        // console.log(res)

        const gettedData = {
          zipCode: res.data.address.legal.zipCode,
          city: res.data.address.legal.city,
          street: res.data.address.legal.street,
          toCreate: false,
          innIsCorrect: res.data.inn || '',
          institutionType: res.data.type,
          inn: res.data.inn || '',
          companyPhone: res.data.phoneNumber || '',
          agree: isEquivalent(res.data.address.legal, res.data.address.actual),
          prevType: res.data.type
        }

        if (res && res.success) {
          this.stateCopy = gettedData;
          this.setState({ ...gettedData })
        }

        return res.data.type !== 'Freelance' ? this.checkInn() : false;
      }).catch( (err) => {
        this.props.load(false)
        console.error('getData', err)
      })
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  toTheNextStep = async (checkValidity) => {
    const { state } = this;
    if (checkValidity("reg-step-first-form")) {
      return false
    }

    if (!state.changed) {
      this.props.updateDoneStep(1);
      history.push(`/registration/step-2`);
      return false
    }

    const legalAddress = state.legalAddress;

    const actualAddress = state.agree ? legalAddress : {
      zipCode: state.zipCode,
      city: state.city,
      street: state.street
    };

    await this.apiService.getResource('api/companyInfo', state.toCreate ? 'POST' : 'PUT', state.institutionType !== 'Freelance' ? {
      type: state.institutionType,
      inn: state.innIsCorrect.replaceAll(' ', '') || state.inn.replaceAll(' ', ''),
      name: state.companyName,
      phoneNumber: state.companyPhone.replaceAll(' ', ''),
      address: {
        legal: legalAddress,
        Actual: actualAddress
      },
      companyId: localStorage.getItem('companyId')
    } : {
      type: state.institutionType,
      companyId: localStorage.getItem('companyId'),
      inn: state.inn,
      phoneNumber: state.toCreate ? localStorage.getItem('phoneNumber') : state.companyPhone
    })
      .then(async (res) => {
        // console.log(res)
        if (!res.success) {
          let innerr=res.message || 'не удалось!'
          this.setState({innerr})
          return false;
        }

        this.props.updateDoneStep(1);
        localStorage.setItem('companyId', res.data.companyId)
        history.push(`/registration/step-2`);
      })
  }

  checkboxHandler = (index) => {
    if (this.state.prevType === index) {
      this.setState({
        ...this.stateCopy,
        institutionType: this.options[index],
        changed: false
      })
    } else {
      this.setState({
        institutionType: this.options[index],
        innIsCorrect: false,
        inn: '',
        changed: true
      })
    }
  }

  render() {
    const { companyPhone, innIsCorrect, inn, institutionType, agree, companyName, ceo_name, ceo_post, legalAddress, zipCode, city, street, innerr, } = this.state;

    return (
      <ValidFuncConsumer>
        {({ checkValidity }) =>
          <form noValidate id="reg-step-first-form" className="reg-step-form" onSubmit={this.onSubmit}>

            <CheckboxList className="institution-types" count={this.options.findIndex((o) => o === institutionType)} text={['Юридическое лицо', 'Индивидуальный предприниматель', 'Фрилансер']} handler={this.checkboxHandler} />

            <div className="row-of-inputs without-media">
              <MyInput title="Введите ИНН" id="inn" value={inn} onChange={(e) => this.setState({ inn: e.target.value, changed: true })} required />
              { innerr ? <div className="redgvn">{innerr}</div> : null }
              {innIsCorrect ? <button type="button" className="primary-text return-to-initial" onClick={() => this.setState({ innIsCorrect: false, inn: '', changed: true })}>Изменить</button> : null}
            </div>

            {innIsCorrect && institutionType !== 'Freelance' ? <>
              <div className="info-block">
                <div className="title-div">{companyName}</div>
                <div className="text-div">{legalAddress.zipCode}, {legalAddress.city}, {legalAddress.street} <br /> {ceo_post && ceo_name ? `${ceo_post} — ${ceo_name}` : null}</div>
                <div className="additional-notice">{`Данные об организации получены автоматически из ${institutionType === 'Individual' ? 'ЕГРЮЛ' : 'ЕГРИП'} ИФНС России на основании введенного ИНН`}</div>
              </div>

              <MyInput title="Телефон организации" autoComplete="tel" id="company-phone" name="company-phone" messagetext="Введите номер в формате 7ХХХХХХХХХХ" value={companyPhone} onInput={(e) => this.setState({ companyPhone: phoneNumberCutter(e.target.value), changed: true })} pattern="^(\+7|7|8)[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$" placeholder="7ХХХХХХХХХХ" required />

              <h4>Фактический адрес</h4>
              <div className="agree-with-container" onClick={() => this.setState({ agree: !this.state.agree, changed: true })}>
                <div className={agree ? "app-checkbox active" : 'app-checkbox'}></div>
                <div className="agree-with">Совпадает с юридическим</div>
              </div>

              {!agree ? <>
                <div className="row-of-inputs first-step">
                  <MyInput title="Индекс" name="point-id" messagetext="Некорректный ввод" value={zipCode} onInput={(e) => this.setState({ zipCode: e.target.value, changed: true })} pattern="^[0-9]{6}$" placeholder="XХХХХХ" required />

                  <MyInput title="Город, регион" name="city-region" value={city} onInput={(e) => this.setState({ city: e.target.value, changed: true })} required />
                </div>

                <MyInput title="Улица, квартира/офис" name="address" value={street} onInput={(e) => this.setState({ street: e.target.value, changed: true })} required />
              </> : null}

            </> : null}

            <div className="btns-container">
              <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() => innIsCorrect || institutionType === 'Freelance' ? this.toTheNextStep(checkValidity) : this.checkInn(checkValidity)}>Продолжить</button>
            </div>
          </form>
        }
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepFirst);
// Главный компонент приложения

import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux";

import { ValidFuncProvider } from '../scripts/validFuncContext'
import ValidFuncService from "../services/validFuncService"
import LoadSpinner from "../utils/spinner"
import RegStepsContainer from "../components/reg-login/registration-steps/reg-steps-container.jsx"
import SignUp from '../components/reg-login/sign-up/sign-up'
import "./app.scss"
import DonePage from "../components/reg-login/registration-steps/done-page"
import EmailConfirm from '../components/reg-login/registration-steps/email-confirm'
import ByToken from '../components/bytoken'


const App = (props) => {
  const validFunc = new ValidFuncService();
  const spinner = props.load ? <LoadSpinner /> : null;
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const doneStep = props.doneStep
// грязный хак. Разрешаем reload когда на doneStep  
  const stepDone = () => doneStep+1<=4 && 0!==doneStep ? false : true
  const rediTo = doneStep+1<=4 && 0!==doneStep ? `/registration/step-${doneStep+1}` : `/done` 

  return (
    <ValidFuncProvider value={validFunc}>
      {spinner}
      <Switch>

        <Route path="/registration/" >
          <RegStepsContainer />
        </Route>

        <Route path="/done"  >
          { stepDone() ? <DonePage /> : <Redirect to={rediTo} /> }
        </Route>

        <Route path="/email-confirm">
          <EmailConfirm />
        </Route>

        <Route path="/enter">
          <SignUp />
        </Route>

        <Route match="*">
          {token ? <ByToken token={token} /> : <Redirect to="/enter" /> }
        </Route>
  
      </Switch>
    </ValidFuncProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    load: state.load,
    doneStep: state.doneStep
  }
};

export default connect(mapStateToProps)(App);
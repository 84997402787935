import React, { Component } from 'react';
import { bodyOnMouseDown, bodyOnEscEnter, bodyOffBindedFuncs } from '../scripts/functions';
import closeImg from '../img/close.png';

class Popup extends Component {

  componentDidMount() {
    const {popupClose, popupName, } = this.props;
    const thisPopup = popupName ? popupName + '.general-popup' : '.general-popup';

    bodyOnMouseDown(() => popupClose(), thisPopup);
    bodyOnEscEnter(() => popupClose())
  };

  componentDidUpdate() {
  };

  componentWillUnmount() {
    bodyOffBindedFuncs();
  }

  render() {
    const {isForm, popupClose, content, withCloseBtn, className} = this.props;
    return (
      isForm 
      ? 
      <form id={isForm} className={`general-popup ${className ? className : null}`}>
        {content}
  
        {withCloseBtn ? <button className="close-btn" onClick={() => popupClose()}><img alt="" src={closeImg} /></button> : null}
      </form>  
      :
      <div className={`general-popup ${className ? className : null}`}>
        {content}
  
        {withCloseBtn ? <button className="close-btn" onClick={() => popupClose()}><img alt="" src={closeImg} /></button> : null}
      </div>  
    );
  }
}

export default Popup